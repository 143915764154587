'use client';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
// import StyledComponentsRegistry from './StyledComponentsRegistry';
import CssVars from './CssVars';
import { theme } from './theme';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

export default function ThemeRegistry({ children }: { children: React.ReactNode }) {
  return (
    <>
      {/* <StyledComponentsRegistry> */}
      {/* <StyledComponentsRegistry> */}
      <CssVarsProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <CssVars />
        {children}
      </CssVarsProvider>
      {/* </StyledComponentsRegistry> */}
    </>
  );
}
