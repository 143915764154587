import type { ComponentsVariants } from '@mui/material/styles';

function importAll(r: __WebpackModuleApi.RequireContext) {
  return r.keys().map((fileName: string) => {
    try {
      const m = {
        [fileName.replace('./', '').replace('.theme.ts', '')]: r(fileName).default
      };
      return m;
    } catch (err) {
      console.log('Error loading', fileName);
      console.log(err);
    }
  });
}

const themesArray = importAll(require.context('../', true, /\.theme\.ts$/));

const themes: ComponentsVariants = themesArray.reduce((acc, current) => {
  return { ...acc, ...current };
}, {});

export default themes;
